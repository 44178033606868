@import url("http://fonts.cdnfonts.com/css/montserrat");

.ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before {
  font-family: "Montserrat", cursive;
  content: "Montserrat" !important;
}

/* Set content font-families */
.ql-font-Montserrat {
  font-family: "Montserrat";
}


.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value);
}

.fff{
  background-color: white;
}

.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 28px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  color: #282828;
  background: conic-gradient(
      from 154.27deg at 50% 50%,
      #e7e7e7 0deg,
      rgba(255, 255, 255, 0) 360deg
    ),
    #bababa;
  border-radius: 10px;
}

.high {
  background: conic-gradient(
      from 154.27deg at 50% 50%,
      #e1ff38 0deg,
      rgba(255, 255, 255, 0) 360deg
    ),
    #8aff61;
}

.low {
  background: conic-gradient(
      from 154.27deg at 50% 50%,
      #ff3838 0deg,
      rgba(255, 122, 122, 0) 360deg
    ),
    #ff6161;
  border-radius: 10px;
}

.css-8cs7k8-MuiPaper-root {
  display: flex;
  justify-content: center;
}

.table-border-line-r {
  border-right: 1px solid #f2f4f5;
}

.table-border-line-b {
  border-bottom: 1px solid #f2f4f5 !important;
}

@media screen and (max-width: 640px) {
  .responsiveTable {
    padding: 0 !important;
  }
}

.fw-inherit {
  font-weight: normal;
}

.listItemTextTitle > span {
  font-weight: bold;
}

.listItemTextValue > span {
  text-align: right;
}

.css-1032q2i-MuiTableCell-root {
  background-color: none !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: -25px;
  left: 70px;
  min-width: 160px;
  background-color: #d1d5d9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.hide {
  display: none;
}

.custom-tooltip {
  background-color: gray;
  padding: 10px;
}