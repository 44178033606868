.warning-block {
  padding: 10px;
  background-color: #ffcc00;
  border-left: 5px solid #ff9900;
  margin: 10px 0;
}

.warning-block strong {
  font-size: 1.2em;
  display: block;
  margin-bottom: 5px;
}

.checked {
    color: green;
}